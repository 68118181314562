@layer components {
  .button {
    @apply no-underline font-medium text-xs text-center px-3 py-1.5 border bg-neutral-50 border-neutral-400 rounded-md focus:outline-none focus:ring-2 focus:ring-neutral-300 hover:bg-neutral-200;
    @apply bg-gradient-to-b from-white to-neutral-200;

    &.selected {
      @apply bg-gradient-to-b from-rose-600 to-rose-700 text-white border-rose-300 focus:ring-rose-100;
    }

    &.is-primary {
      @apply bg-gradient-to-b from-rose-600 to-rose-700 text-white border-rose-600 focus:ring-rose-100;
      &:hover{
        @apply bg-gradient-to-b from-rose-600 to-rose-600 text-white border-rose-600 focus:ring-rose-300;
      }      
    }

    &.is-secondary {
      @apply bg-gradient-to-b from-rose-50 to-rose-200 text-black border-rose-400 focus:ring-rose-200;
      &:hover{
        @apply bg-gradient-to-b from-rose-100 to-rose-300 text-black border-rose-500 focus:ring-rose-300;
      }      
    }  

    &.is-danger {
      @apply bg-gradient-to-b from-red-500 to-red-600;
      @apply text-white bg-red-700 border-red-700 focus:ring-red-300
    }

    &.is-medium {
      @apply text-sm px-3 py-2;
    }

    &.is-large {
      @apply text-sm px-5 py-2.5;
    }
  }

  button:disabled {    
    color: #ababab;
    cursor: not-allowed;
  }
}