@layer components {
  label {
    @apply text-sm font-medium text-gray-900;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"],
  select,
  textarea {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded focus:ring-rose-500 focus:border-rose-500 inline-block px-2 py-1.5 w-full;

    &[disabled] {
      @apply cursor-not-allowed;
    }
  }

  input[type="file"] {
    @apply text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 focus:outline-none w-full;

    &[disabled] {
      @apply cursor-not-allowed;
    }
  }

  input[type="checkbox"] {
    @apply w-4 h-4 text-rose-600 bg-gray-100 border-gray-300 rounded focus:ring-rose-500 focus:ring-2;

    &[disabled] {
      @apply cursor-not-allowed;
    }
  }

  input[type="radio"] {
    @apply w-4 h-4 text-rose-600 bg-gray-100 border-gray-300 focus:ring-rose-500 focus:ring-2;

    &[disabled] {
      @apply cursor-not-allowed;
    }
  }

  input[type="range"] {
    @apply w-full h-2 bg-gray-200 rounded appearance-none cursor-pointer;
  }

  select {
    @apply cursor-pointer focus:ring-rose-500 focus:border-rose-500;
  }


}
