@layer components {
  table {
    @apply text-sm text-gray-700 text-left;
  }

  thead  {
    @apply text-xs text-white uppercase bg-rose-700;
    position: sticky;
    top: 62px;
    z-index: 1;
  }

  tr td {
    @apply px-1 py-[4px] border border-stone-200 h-[36px];
  }

  tr th {
    @apply px-4 py-4;
  }

  tr th a {
    cursor: pointer;
    &:hover { @apply underline; }
    margin-right: 2px;
  }

  tr th[scope="row"] {
    @apply font-medium text-gray-900 whitespace-nowrap;
  }

  tbody tr {
    @apply bg-white border-b hover:bg-gray-50;
  }
}