@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,900&display=swap');

@import 'vendors/fontawesome/fontawesome';
@import 'vendors/tailwind/tailwind';

@import 'components/button';
@import 'components/form';
@import 'components/table';

h1 > i { @apply text-rose-800; }

// .fa-mobile { color:rgb(48, 46, 163); }
// .fa-weixin { color: #09B83E; }
// .fa-whatsapp { color: #25D366; }
// .fa-square-facebook { color: #1877F2; }

.fa-check.green { color: #09B83E; }
.fa-xmark.red { color: #a71528; }

.breadcrumbs {
  margin: 16px 0 6px 0;
  font-size: 0.875rem;

  li {
    display: inline-block;
  }

  // show icon before every li except the first one
  li:not(:first-child):before {
    content: '>';
    margin: 0 8px;
  }
}


.breadcrumb {
  margin: 6px 0;
  font-size: 0.875rem;

  a { cursor: pointer; }
  li a { cursor: pointer; }
  li a:hover { text-decoration: underline; }

  li:not(:last-child) {
    display: inline-block;
    margin-right: 6px;
  }

  li i { margin-left: 2px; }

  &.hierarchy li:not(:first-child):before {
    font-family: "Font Awesome 6 Pro";
    content: "\f105";
    display: inline-block;
    padding-right: 6px;
    vertical-align: middle;
  }

  &.sort li:nth-child(n+3):before {
    font-family: "Font Awesome 6 Pro";
    content: "\f105";
    display: inline-block;
    padding-right: 8px;
    vertical-align: middle;
  }  

  &.sort a.reset i { vertical-align: baseline; @apply text-rose-700;  }
}